import Chart from "../../../node_modules/chart.js/dist/chart.js";
import HSSideNav from "../theme/aside.min"

var oSTable = null;
document.addEventListener("turbolinks:before-cache", function() {
    if (oSTable !== null) {
        oSTable.destroy()
        oSTable = null
    }
    $("select.select2-hidden-accessible").select2('destroy');
})

$(document).on('turbolinks:load', function () {

    // new HSSideNav('.js-navbar-vertical-aside').init()
    setSidebarClass()
    // const body = document.getElementsByTagName('body')[0],
    //     isMini = window.localStorage.getItem('hs-navbar-vertical-aside-mini') === null ? false : window.localStorage.getItem('hs-navbar-vertical-aside-mini');
    //
    // if (isMini) {
    //     body.classList.add('navbar-vertical-aside-mini-mode')
    // }

    $('.sidebar-toggle').on('click', function () {
        if (window.innerWidth < 1200) {
            $('body').toggleClass('navbar-vertical-aside-closed-mode')
        }
    });

    $(document).on("change", ".coupon_discount_type", function () {
        $('.discount-fields').addClass('d-none');
        if ($(this).val() === 'Flat Discount') {
            $('.discount-value-field').removeClass('d-none');
        } else {
            $('.discount-percentage-field').removeClass('d-none');
            $('.discount-max-value-field').removeClass('d-none');
        }
    });

    $('.checkout-name').on('keyup',function (){
        $('#checkout_form_checkout_url').val($(this).val().replace(/[^A-Z0-9]/gi,''))
    })

    $('.tour').on('click', function () {
        $.ajax({
            type: "GET",
            dataType: "script",
            url: '/tour/restart',
            data: {},
            success: function (data) {
            }
        });
    });

    checkedAllCheckbox('.all-checkbox');
    $(".select2").select2({
        width: '100%'
    });

    $('.select2-add').select2({
        allowClear: true,
        width: '100%',
        language: {
            noResults: function () {
                return `<a style="width: 100%" type="button" class="btn btn-primary add-record">+ Add New Items</a>`;
            }
        },
        escapeMarkup: function (markup) {
            return markup;
        }
    });

    $('.select2-add-custom')
        .select2()
        .on('select2:open', () => {
            $(".select2-results:not(:has(a))").append('<a href="/segments/new" data-remote="true" style="padding: 6px;height: 20px;display: inline-table;">Nieuw segment maken</a>');
        })

    $('#alias_modal').on('show', function () {
        $('#myInput').trigger('focus')
    })

    $('#alias_modal').modal('show')

    // $(".trix-content figure.attachment").closest("a").fancybox({
    //     helpers: {
    //         title : {
    //             type : 'float'
    //         }
    //     }
    // });
    $(".set-date").flatpickr({
        dateFormat: "Y-m-d",
        "locale": "nl"
    });
    $(".set-course-date").flatpickr({
        dateFormat: "Y-m-d",
        "locale": "nl"
    });
    $(".set-time").flatpickr({
        noCalendar: true,
        dateFormat: "H:i",
        enableTime: true,
        time_24hr: true
    });
    $('.scroll').click(function () {
        $('body').animate({
            scrollTop: eval($('#' + $(this).attr('target')).offset().top - 70)
        }, 1000);
    });

    $(".change-month, .change-year, .change-plan").change(function () {
        var month = $("#date_month option:selected").text()
        var year = $("#date_year option:selected").text()
        var plan_id = $(".change-plan option:selected").val()
        $.ajax({
            type: "GET",
            dataType: "script",
            url: '/subscriptions/subscription_detail',
            data: {month: month.split(' - ')[0], year: year, plan_id: plan_id},
            success: function (data) {
            }
        });
    });
    init_filepond_profile_pic();


    // if (App.isAngularJsApp() === false) {
    //     jQuery(document).ready(function() {
    //         TableDatatablesManaged.init();
    //     });
    // }
    // var oSTable
    $("table[role='datatable']").each(function (e) {
        if ($(this).data('role') === 'new_table') {
            oSTable = $(this).DataTable({
                dom: '"<\'row\'<\'col-sm-12\'tr>>" +"<\'row\'<\'col-sm-8\'p><\'col-sm-4\'l>>", ',
                "lengthMenu": [[10, 25, 50, 100], [10, 25, 50, 100]],
                bProcessing: true,
                bServerSide: true,
                bLengthChange: true,
                searching: false,
                pageLength: 10,
                bInfo: true,
                pagingType: 'input',
                "language": {
                    "thousands": ".",
                    "aria": {
                        "sortAscending": ": activate to sort column ascending",
                        "sortDescending": ": activate to sort column descending"
                    },
                    "emptyTable": "No data available in table",
                    "info": "_START_ van _MAX_",
                    "infoEmpty": "No records found",
                    "infoFiltered": "",
                    "lengthMenu": "_MENU_",
                    "search": "",
                    "searchPlaceholder": "Zoeken...",
                    "zeroRecords": "No matching records found",
                    "paginate": {
                        "previous": "<i class=\"fas fa-chevron-left\"></i>",
                        "next": "<i class=\"fas fa-chevron-right\"></i>",
                        "last": "<i class=\"fas fa-chevron-double-right\"></i>",
                        "first": "<i class=\"far fa-chevron-double-left\"></i>"
                    },
                },
                // "ordering": false,
                "ordering": true,
                "order": [[0, "desc"]],
                "aoColumnDefs": [
                    {'bSortable': false, 'aTargets': [-1, 'no-sort']}
                ],
                sAjaxSource: $(this).data('source')
            })
        }
        else
        {

            oSTable = $(this).DataTable({
                "lengthMenu": [[10, 25, 50, 100], [10, 25, 50, 100]],
                bProcessing: true,
                bServerSide: true,
                bLengthChange: true,
                pageLength: 10,
                bInfo: true,
                "language": {
                    "thousands": ".",
                    "aria": {
                        "sortAscending": ": activate to sort column ascending",
                        "sortDescending": ": activate to sort column descending"
                    },
                    "emptyTable": "No data available in table",
                    "info": "Showing _START_ to _END_ of _TOTAL_ records",
                    "infoEmpty": "No records found",
                    "infoFiltered": "(filtered1 from _MAX_ total records)",
                    "lengthMenu": "_MENU_",
                    "search": "Zoeken:",
                    "zeroRecords": "No matching records found",
                    "paginate": {
                        "previous": "Vorige",
                        "next": "Volgende",
                        "last": "Last",
                        "first": "First"
                    }
                },
                // "ordering": false,
                "ordering": true,
                "order": [[0, "desc"]],
                "aoColumnDefs": [
                    {'bSortable': false, 'aTargets': [-1, 'no-sort']}
                ],
                sAjaxSource: $(this).data('source')
            })
        }
    });

    $(".select-plan").change(function () {
        oSTable.ajax.url("/users.json?plan_id=" + $('#plan_id').val() + '&name=' + $('#search_name').val()).load()
        if($('#plan_id').val()){
            $("#removeSegmentBtn").removeClass('d-none').attr('href', '/segments/'+$('#plan_id').val())
        }
    });
    $(".search-name").keyup(function () {
        if ($(this).val().length >= 2) {
            oSTable.ajax.url("/users.json?name=" + $('#search_name').val() + '&plan_id=' + $('#plan_id').val()).load()
        } else {
            oSTable.ajax.url("/users.json?").load()
        }
    });

    $(".search_data").keyup(function () {
        if ($(this).val().length >= 2) {
            oSTable.ajax.url($(this).parent().parent().next().find('table').data('source')+"?name=" + $('#search-data').val()).load()
        } else {
            oSTable.ajax.url($(this).parent().parent().next().find('table').data('source')).load()
        }
    });

    $('.user-data').on('click', 'tbody tr td', function() {
        window.location.href = $(this).find("div").children().attr('href');
    });

    $(".select-active").change(function () {
        oSTable.ajax.url("/subscriptions.json?active=" + $(".select-active").is(':checked')).load()

    });

    $(document).on('keyup', '.check-price', function (event) {
        if ($(this).val().length >= 2) {
            if ($(this).val() <= 90) {
                $(this).parent().parent().next('div').find('input[type="checkbox"]').prop('checked', true);
            } else {
                $(this).parent().parent().next('div').find('input[type="checkbox"]').prop('checked', false);
            }
        }
    })

    $('#user_tags, #checkout_form_key_points').tagsinput({
        confirmKeys: [188, 13, 44],
        trimValue: true
    });

    $("#search_report").on("keyup", function () {
        $.ajax({
            type: "POST",
            dataType: "script",
            url: '/search_report',
            data: {search_query: $(this).val()},
            success: function (data) {
            }
        });
    });
    setTooltip()
    $('.off-app #copy-btn').hover(function(e){
        if ($('#checkout_form_checkout_url').val().length > 0) {
            setTooltip($(this), 'Click To Copy!');
        }
    });

    $("#copy-btn").click(function () {
        if ($('#checkout_form_checkout_url').val().length > 0){
            $(this).prev(".copy-url").val($('.url-field').html() + $('#checkout_form_checkout_url').val());
            var copyText = $(this).prev(".copy-url");
            copyText.attr("type", "text").select();
            document.execCommand("copy");
            copyText.attr("type", "hidden");
            setTooltip($(this), 'Copied!');
        }else{
            setTooltip($(this), 'Value is blank!');
        }
    });

    $(".select-status").change(function () {
        oSTable.ajax.url("/sales_orders.json?status=" + $('input[name=status-button]:checked').val()).load()

    });

    $(".open-menu").click(function () {
        $(".page-sidebar").toggleClass("show");
        $("body").toggleClass("menu-open");
    });

    $(".close-menu").click(function () {
        $(".page-sidebar").removeClass("show");
        $("body").removeClass("menu-open");
    });
    $('.interval-options form input[type=radio]').on('change', function () {
        $('.interval-options form').submit();
    });
    // $('.available-plans .col-lg-4 .description').matchHeight();
    // $('.download-items .col-lg-4 .portlet-title').matchHeight();
    // $('.download-items .col-lg-4 .portlet-body').matchHeight();
    // $('.download-items .col-lg-4 .portlet-title').matchHeight();
    // $('.download-items .col-lg-4 .portlet-body').matchHeight();
    // $('.updates .portlet-body').matchHeight();
    // $('.onsite-cources .col-lg-4 .portlet-body').matchHeight();
    $('#online_course_plug_pay_ids').tagsinput({
        confirmKeys: [188, 13, 44],
        trimValue: true
    });
    $('#survey_question_qus_choices').tagsinput({
        confirmKeys: [188, 13, 44],
        trimValue: true
    });
});

$(document).on("change", ".toggle-renew", function () {
    var confirm_box = confirm("Weet je zeker dat je je instellingen wilt veranderen?");
    if (confirm_box == true) {
        $.ajax({
            url: '/update_auto_renew',
            type: 'POST',
            dataType: 'script',
            data: {current_subscription: {auto_renew: $(this).is(':checked')}},
            success: function (result) {
            }
        });
    } else {
        $(this).prop("checked", !$(this).is(':checked'));
    }
});
$(document).on("change", "#survey_question_qus_type", function () {
    if ($('#survey_question_qus_type :selected').text() == "Multiple choice") {
        $('.qus_choice').removeClass('d-none')
    } else {
        $('.qus_choice').addClass('d-none')
    }
});

$(document).on("change", ".chk-qus-choice", function () {
    if ($("input[name='" + $(this).attr('name') + "']:checked").length == 0) {
        $("input[name='" + $(this).attr('name') + "']").attr("required", true)
    } else {
        $("input[name='" + $(this).attr('name') + "']").attr("required", false)
    }
});

$(document).on("click", ".sale-check", function () {
    if ($("#product_is_sale").is(':checked')) {
        $('.sale-price').removeClass('d-none')
        $('#product_sale_price').attr("required", true);
    } else {
        $('.sale-price').addClass('d-none')
        $('#product_sale_price').attr("required", false);
    }
});

$(document).on("click", ".add-record", function () {
    $('#oldInstrumentModal').modal('show');
    $("#instrument_display_name").val($('.select2-search__field').val())
    console.log($('.select2-add .select2-search__field').val())
});

function checkedAllCheckbox(checkedAllCheckboxElem) {
    $(checkedAllCheckboxElem).each(function () {
        var mainObj = $(this)
        var subCheckboxElem = $('.' + mainObj.data('chk-class'));
        mainObj.on("click", function () {
            subCheckboxElem.prop("checked", mainObj.is(':checked'));
        });
    });
}

export function portfolioGraph(keys, values) {
    const ctx = document.getElementById('myChart').getContext('2d');
    const myChart = new Chart(ctx, {
        type: 'line',
        data: {
            datasets: [{
                label: 'stock',
                data: values,
                borderColor: ['#2E3192'],
                fill: false,
                borderWidth: 3,
                tension: 0.1
            }],
            labels: keys
        },

        options: {
            plugins: {
                legend: {
                    display: false
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                enabled: false
            },
            scales: {
                x: {
                    grid: {
                        display: false
                    },
                    display: true,

                },
                y: {
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        suggestedMin: 0,
                        callback: function (value) {
                            return value + "%"
                        },
                    }
                }
            },
            elements: {
                line: {
                    borderWidth: 3
                },
                point: {
                    radius: 0
                }
            }
        }
    });
}

export function  init_filepond_profile_pic() {
    $(".filepond-profile-pic").each(function () {
        if ($(this).data("name")) {
            createprofilePoster(
                this,
                $(this).data("name"),
                $(this).data("size"),
                $(this).data("type"),
                $(this).data("url"),
                $(this).data("remove-url"),
                $(this).data("id")
            );
        } else {
            FilePond.create(this, {
                    labelIdle: 'Selecteer <span class="filepond--label-action">afbeelding</span>',
                    imagePreviewHeight: 170,
                    imageCropAspectRatio: '1:1',
                    imageResizeTargetWidth: 200,
                    imageResizeTargetHeight: 200,
                    stylePanelLayout: 'compact circle',
                    styleLoadIndicatorPosition: 'center bottom',
                    styleButtonRemoveItemPosition: 'center bottom'
                }
            );
        }
    });
}

function createprofilePoster(input, name, size, type, url, delete_url, item_id) {
    FilePond.create(input, {
        name: "filepond",
        imagePreviewHeight: 170,
        imageCropAspectRatio: '1:1',
        imageResizeTargetWidth: 200,
        imageResizeTargetHeight: 200,
        stylePanelLayout: 'compact circle',
        styleLoadIndicatorPosition: 'center bottom',
        styleButtonRemoveItemPosition: 'center bottom',
        files: [{
            options: {
                file: {
                    name: name,
                    size: size,
                    type: type
                },
                metadata: {
                    poster: url
                }
            }
        }]
    });
    $(".filepond-profile-pic").on("FilePond:removefile", function (e) {
        var id = item_id;
        if (id) {
            $.ajax({
                url: delete_url,
                type: "POST",
                datatype: "Script",
                data: {id: id},
                success: function (result) {
                }
            });
        }
        init_filepond_profile_pic();
    });
}

// $( window ).resize(function() {
//     setSidebarClass()
// });
function setSidebarClass() {
    if (window.innerWidth < 1200) {
        $('body').addClass('navbar-vertical-aside-closed-mode')
    } else {
        $('body').removeClass('navbar-vertical-aside-closed-mode')
        new HSSideNav('.js-navbar-vertical-aside').init()
    }
}
function setTooltip(btn, message) {
    $(btn).tooltip('show')
        .attr('data-bs-original-title', message)
        .tooltip('show');
}
