document.addEventListener("turbolinks:load", function () {
    if ($("#checkoutFrom").length){
        initialize_form_validation("checkoutFrom")
        $('#cart_issuer').val(" ")
        setKvkNumberField();
        $('.ideal_bank_select').select2({
            theme: 'bootstrap',
            minimumResultsForSearch: -1,
            templateResult: formatState,
            width: '100%'
        });
    }
});

$(document).on("change", "#checkoutFrom #user_country", function () {
    $.ajax({
        url: `/update_shipping_cost?checkout_url=${$("#checkoutFrom").data('checkouturl')}&country=${$(this).val()}`,
        dataType: "script"
    })
});

$(document).on("click", "#checkoutFrom .is-ideal", function () {
    if ($(this).is(':checked')) {
        if ($(this).val() === 'ideal') {
            $('.ideal_payment').find('select').attr('required', true)
            $('.ideal_payment').removeClass('d-none')
        } else {
            $('.ideal_payment').find('select').attr('required', false)
            $('.ideal_payment').addClass('d-none')
        }
    } else {
        $('.ideal_payment').find('select').attr('required', false)
        $('.ideal_payment').addClass('d-none')
    }
});

$(document).on("keyup", "#checkoutFrom #user_company_name", function () {
    setKvkNumberField();
});
$(document).on("click", "#checkoutFrom .payoutSwitch", function () {
    $(".payoutSwitch").removeClass('btn-primary');
    $(this).addClass('btn-primary');
});


function setKvkNumberField() {
    if($("#user_company_name").val().length > 0){
        $(".kvk-number-field").removeClass('d-none')
    } else {
        $(".kvk-number-field").addClass('d-none')
    }
}

function formatState(state) {
    if (state.id == null) {
        return state.text;
    }
    var $state = $('<span><img src=\'' + $(state.element).attr('imagesrc') + '\' class="img-payment"/> ' + state.text + '</span>');
    return $state;
}

// $(document).on('change', '#checkoutFrom #user_email', function(e){
//     checkEmailExist($(this).val())
// });
//
// function checkEmailExist(email) {
//     $.ajax({
//         type: "POST",
//         dataType: "script",
//         url: '/check_user_email',
//         data: {
//             user: {email: email},
//         },
//         success: function (data) {
//             if(data == "true"){
//                 $("input[name='user[email]']").val('')
//                 $("#loginModal input[name='user[email]']").val(email)
//                 // $("#loginModal").modal({backdrop: 'static', keyboard: false}, 'show');
//                 $("#loginModal").modal('show');
//             }
//         }
//     });
// }
