
//jQuery time
$(document).on('turbolinks:load', function () {
    var current_fs, next_fs, previous_fs; //fieldsets
    var left, opacity, scale; //fieldset properties which we will animate
    var animating; //flag to prevent quick multi-click glitches

    $(".next-survey-btn").click(function(){
        if(animating) return false;
        if(!$("#new_survey").valid()){
            return false;
        } else {
            $("#survey_ans-error").remove();
        }
        animating = true;

        current_fs = $(this).parent();
        if(current_fs.find("input[name='survey[ans][9][]").length && $("input[name='survey[ans][9][]']:checked").val() != "Ja"){
            next_fs = $(this).parent().next().next();
        } else {
            next_fs = $(this).parent().next();
        }

        //hide the current fieldset with style
        current_fs.animate({opacity: 0}, {
            step: function(now, mx) {
                //as the opacity of current_fs reduces to 0 - stored in "now"
                //1. scale current_fs down to 80%
                scale = 1 - (1 - now) * 0.2;
                //2. bring next_fs from the right(50%)
                left = (now * 50)+"%";
                //3. increase opacity of next_fs to 1 as it moves in
                opacity = 1 - now;
                current_fs.css({
                    'transform': 'scale('+scale+')'
                });
                next_fs.css({'left': left, 'opacity': opacity});
            },
            duration: 300,
            complete: function(){
                current_fs.hide();
                next_fs.show();
                animating = false;
            }
        });

    });

    $(".previous-survey-btn").click(function(){
        if(animating) return false;
        animating = true;

        current_fs = $(this).parent();
        if(current_fs.find("input[name='survey[ans][11][]").length && $("input[name='survey[ans][9][]']:checked").val() != "Ja"){
            previous_fs = $(this).parent().prev().prev();
        } else {
            previous_fs = $(this).parent().prev();
        }

        //hide the current fieldset with style
        current_fs.animate({opacity: 0}, {
            step: function(now, mx) {
                //as the opacity of current_fs reduces to 0 - stored in "now"
                //1. scale previous_fs from 80% to 100%
                scale = 0.8 + (1 - now) * 0.2;
                //2. take current_fs to the right(50%) - from 0%
                left = ((1-now) * 50)+"%";
                //3. increase opacity of previous_fs to 1 as it moves in
                opacity = 1 - now;
                current_fs.css({'left': left});
                previous_fs.css({'transform': 'scale('+scale+')', 'opacity': opacity});
            },
            duration: 300,
            complete: function(){
                current_fs.hide();
                previous_fs.show();
                animating = false;
            }
        });
    });
});
