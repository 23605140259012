import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

$(document).on('turbolinks:load', function () {

    const calendarEl = document.getElementById('calendar')
    if(calendarEl) {
        const calendar = new Calendar(calendarEl, {
            initialView: 'timeGridWeek',
            plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
            themeSystem: 'bootstrap',
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            },
            navLinks: true, // can click day/week names to navigate views
            dayMaxEvents: true, // allow "more" link when too many events
            selectable: true,
            eventClick: function (info) {
                var eventObj = info.event;

                $.ajax({
                    url: `/agendas/${eventObj.id}/edit`,
                    dataType: "script"
                })

            },
            select: function (info) {
                $.ajax({
                    url: '/agendas/new',
                    dataType: "script",
                    data: {
                        start: moment(info.startStr).format('YYYY-MM-DD HH:MM'),
                        end: moment(info.endStr).format('YYYY-MM-DD HH:MM')
                    }
                })
            },
            events: function (info, successCallback, failureCallback) {
                console.log(info.start)
                $.ajax({
                    url: '/agendas',
                    dataType: "json",
                    data: {
                        start: moment(info.start).format('YYYY-MM-DD HH:MM'),
                        end: moment(info.end).format('YYYY-MM-DD HH:MM')
                    }
                })
                    .done((data) => {
                        successCallback(data)
                    })
                    .fail((error) => {
                        failureCallback(error)
                    })
            },
        });

        calendar.render()
    }

});
