/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


console.log('Hello World')

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require('jquery')
import * as $ from 'jquery';

window.bootstrap = require('bootstrap')
window.Popper = require('@popperjs/core');

import moment from "moment";
window.moment = moment;

require("../custom/custom")
require("../custom/survey_steps")
require("../custom/calendar_event")
require("../custom/checkout")
require("jquery-validation")

// window.HSSideNav = require("../theme/aside.min")
require("chart.js")
import 'flatpickr';
import { Dutch } from '../../../node_modules/flatpickr/dist/l10n/nl';

window.Shepherd = require("../../../node_modules/shepherd.js/dist/js/shepherd.min")
require("select2");
require("fullcalendar");
require("@nathanvda/cocoon");

import DataTable from "datatables.net-bs5"
window.DataTable = DataTable;

require("trix")
require("@rails/actiontext")
require("bootstrap-tagsinput")
require("../theme/input")
require("../theme/step-form");
require("../theme/checkouts_form");

import initialize_form_validation from '../custom/kassi'
window.initialize_form_validation = initialize_form_validation;

import * as FilePond from "filepond";
window.FilePond = FilePond;
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
window.FilePondPluginFileEncode = FilePondPluginFileEncode;
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
window.FilePondPluginFileValidateType = FilePondPluginFileValidateType;
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
window.FilePondPluginImagePreview = FilePondPluginImagePreview;
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
window.FilePondPluginFilePoster = FilePondPluginFilePoster;
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
window.FilePondPluginImageCrop = FilePondPluginImageCrop;


FilePond.registerPlugin(
    FilePondPluginFileEncode,
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginFilePoster,
    FilePondPluginImageCrop
);

global.$ = jQuery;
global.toastr = require("toastr");

import "../stylesheets/application.scss";

import { portfolioGraph } from '../custom/custom';
window.portfolioGraph = portfolioGraph;
import {init_filepond_profile_pic} from '../custom/custom'
window.init_filepond_profile_pic = init_filepond_profile_pic;