import HSStepForm from "./step-form";

$(document).on('turbolinks:load', function () {
    initialize_signup_form();
    var courseForm = $("#newCheckoutForm");

    new HSStepForm($('.checkout-step-form'), {
        isValidate: true,
        finish: function () {
            courseForm.submit();
        },
        onStepChanging: function (el, params, nextEl) {
            return sendCourseForm();

        },
        onNextStep: function (el, params, nextEl) {
            scrollToTop('.checkout-step-form')
        },
        onPrevStep: function () {
            scrollToTop('.checkout-step-form')
        }
    }).init();
});

function scrollToTop(el) {
    $('html, body').animate({
        scrollTop: $(el).offset().top - 60
    }, 500)
}

function sendCourseForm() {

    var courseFormObj = $("#newCheckoutForm")

    var flag = false;

    $.ajax({
        url: courseFormObj.attr('action'),
        type: courseFormObj.attr('method'),
        dataType: "script",
        async: false,
        data: courseFormObj.serialize(),
        success: function (response) {
            flag = true;
        },
        error: function (response) {

            flag = false;
        }
    });
    return flag;
}

export function initialize_signup_form() {

    $.validator.addMethod('regex', function (value, element, parameter) {
        return this.optional(element) || /^[A-Za-z0-9\-]*$/.test(value);
    }, '');

    $("#newCheckoutForm").validate({
        rules: {
            "checkout_form[checkout_url]": {
                regex: true
            }
        },
        errorPlacement: function (error, element) {
            element.closest("div").append(error);


        },
        messages: {
            "checkout_form[checkout_url]": {
                regex: "Only number and character are allowed.Space is not allowed."
            }
        }
    });
}
